import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"

/**
 * TODO
 */
const ContentPartnersBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <div
      style={{
        backgroundColor: "#4499DB",
        paddingTop: size(bpt, "8vw", "5vw", "5vw", "5vw"),
        paddingBottom: size(bpt, "10vw", "8vw", "8vw", "8vw"),
      }}
    >
      {/* Heading */}
      <p
        style={{
          marginBottom: size(bpt, "10vw", "5vw", "5vw", "5vw"),
        }}
        className="
          col-start-1 col-span-full
          text-white
          font-bold
          text-center
          text-xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
      >
        We've partnered with the best
      </p>

      <GridLayout type="fluid" container={false} offsets={true}>
        {/* Logos */}
        <div
          style={{
            rowGap: size(bpt, "6vw"),
            columnGap: size(bpt, undefined, "4vw", "4vw", "4vw"),
          }}
          className="
            col-start-1 col-span-full
            grid 
            tablet:grid-flow-col-dense
            grid-cols-2 tablet:grid-cols-none
            grid-rows-3 tablet:grid-rows-none
            place-items-center"
        >
          {/* Penguin RandomHouse */}
          <div
            style={{
              width: size(bpt, undefined, "12vw", "12vw", "12vw"),
              marginLeft: size(bpt, "9vw"),
              marginRight: size(bpt, "9vw"),
            }}
          >
            <StaticImage
              src="../../images/home/partnerLogos/penguin-random-house-logo.webp"
              alt="Penguin RandomHouse logo"
              loading="eager"
              placeholder="none"
            />
          </div>

          {/* HarperCollins */}
          <div
            style={{
              width: size(bpt, undefined, "18vw", "18vw", "18vw"),
              marginLeft: size(bpt, "4vw"),
              marginRight: size(bpt, "4vw"),
              opacity: 0.8,
            }}
          >
            <StaticImage
              src="../../images/home/partnerLogos/harpercollins-logo.webp"
              alt="HarperCollins logo"
              loading="eager"
              placeholder="none"
            />
          </div>

          {/* DK */}
          <div
            style={{
              width: size(bpt, undefined, "6vw", "6vw", "6vw"),
              marginLeft: size(bpt, "14vw"),
              marginRight: size(bpt, "14vw"),
              opacity: 0.75,
            }}
          >
            <StaticImage
              src="../../images/home/partnerLogos/dk-logo.webp"
              alt="Blackstone Publishing logo"
              loading="eager"
              placeholder="none"
            />
          </div>

          {/* Blackstone Publishing */}
          <div
            style={{
              width: size(bpt, undefined, "18vw", "18vw", "18vw"),
              marginLeft: size(bpt, "5vw"),
              marginRight: size(bpt, "5vw"),
              opacity: 0.8,
            }}
          >
            <StaticImage
              src="../../images/home/partnerLogos/blackstone-publishing.webp"
              alt="Blackstone Publishing logo"
              loading="eager"
              placeholder="none"
            />
          </div>

          {/* Naxos */}
          <div
            style={{
              width: size(bpt, undefined, "7vw", "7vw", "7vw"),
              marginLeft: size(bpt, "33vw"),
              marginRight: size(bpt, "33vw"),
              opacity: 0.9,
            }}
            className="col-span-2 tablet:col-auto"
          >
            <StaticImage
              src="../../images/home/partnerLogos/naxos-logo.webp"
              alt="Naxos Audiobooks logo"
              loading="eager"
              placeholder="none"
            />
          </div>
        </div>
      </GridLayout>
    </div>
  )
}

export default ContentPartnersBlock
