import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext, useState } from "react"

/**
 * Hero banner block
 */
const HeroBannerBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // State of load of main banner image
  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <div className="relative top-0 left-0 right-0">
      <StaticImage
        src="../../images/home/hero-banner.webp"
        alt="Girl with Player over cover art"
        layout="fullWidth"
        loading="eager"
        placeholder="none"
        onLoad={() => {
          setImageLoaded(true)
        }}
      />

      <GridLayout
        type="fluid"
        container={false}
        offsets={true}
        className="absolute bottom-0 left-0 right-0"
      >
        {imageLoaded && (
          <span
            className="
              col-start-1 col-span-full
              laptop:tracking-wider
              leading-6 tablet:leading-tight laptop:leading-tight
              text-white
              font-bold"
            style={{
              fontSize: size(bpt, "5vw", "5vw", "4.6vw", "4.6vw"),
              marginBottom: size(bpt, "8vw", "7.5vw", "9vw", "9vw"),
              textShadow: "0 0.2vw 0.2vw rgba(0, 0, 0, 0.4)",
            }}
          >
            Awesome audio,
            <br />
            just for kids!
          </span>
        )}
      </GridLayout>
    </div>
  )
}

export default HeroBannerBlock
