import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"
import TextBlock from "./textBlock"

/**
 * Superfonik introduction block, with platform lock-up
 * and overview of components.
 */
const IntroBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      {/* Description */}
      <p
        style={{
          fontSize: size(bpt, "5.8vw"),
          marginLeft: size(bpt, "-1vw"),
          marginRight: size(bpt, "-1vw"),
        }}
        className="
          col-start-1 col-span-full
          text-center
          font-bold
          tablet:text-4xl-fluid-t laptop:text-5xl-fluid-l"
      >
        <span className="text-red">Superfonik</span> &mdash; a universe of audio
        entertainment for children
      </p>

      {/* Device Lock-up */}
      <div
        style={{
          marginTop: size(bpt, "6vw", "4vw", "6vw", "8vw"),
          marginBottom: size(bpt, "8vw", "6vw", "6vw", "8vw"),
        }}
        className="
          col-start-1 tablet:col-start-2
          col-span-full tablet:col-span-10"
      >
        <StaticImage
          src="../../images/home/device-lockup.webp"
          alt="Device lock-up"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>

      {/* Store copy */}
      <TextBlock
        heading="A stellar store"
        style={{
          marginLeft: size(bpt, "-4.5vw"),
        }}
        className="
          col-start-2 
          col-span-full tablet:col-span-4"
      >
        Instantly access thousands of the best audiobooks, podcasts and radio
        stations, exclusively for children.
      </TextBlock>

      {/* Library */}
      <TextBlock
        heading="A family library"
        style={{
          marginTop: size(bpt, "4vw"),
          marginLeft: size(bpt, "-4.5vw", "1vw", "1vw", "1vw"),
        }}
        className="
          col-start-2 tablet:col-start-5 
          col-span-full tablet:col-span-4"
      >
        Curate collections of audio for each child from your shared family
        library of purchased content.
      </TextBlock>

      {/* Player */}
      <TextBlock
        heading="A playful player"
        style={{
          marginTop: size(bpt, "4vw"),
          marginLeft: size(bpt, "-4.5vw"),
        }}
        className="
          col-start-2 tablet:col-start-9 
          col-span-full tablet:col-span-4"
      >
        Your child can browse and play their favourite audio on any iPhone or
        iPad device using our intuitive player.
      </TextBlock>
    </GridLayout>
  )
}

export default IntroBlock
