import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"

/**
 * TODO
 */
const PlayerAppFeaturesBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        pt-m-block-fluid
        tablet:pt-t-block-fluid
        laptop:pt-l-block-fluid
        desktop:pt-d-block-fluid"
    >
      {/* Copy block */}
      <div
        style={{
          paddingRight: size(bpt, undefined, "5vw"),
        }}
        className="
          col-start-1 tablet:col-start-1
          col-span-full tablet:col-span-6
          row-start-1
          flex flex-col justify-center"
      >
        <p
          style={{
            paddingRight: size(bpt, "6vw"),
            marginRight: size(bpt, undefined, "-5vw"),
          }}
          className="
            font-bold
            leading-tight
            text-2xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
        >
          A load of fun features{" "}
          <span className="text-red">at their fingertips</span>
        </p>

        <ul
          style={{
            marginTop: size(bpt, "3vw", "2vw", "2vw", "2vw"),
            marginLeft: size(bpt, "5vw", "2.5vw", "2.5vw", "2.2vw"),
            paddingRight: size(bpt, "10vw", "2vw", "5vw", "5vw"),
          }}
          className="
              list-disc 
              grid gap-3
              text-sm-fluid tablet:text-sm-fluid-t laptop:text-lg-fluid-l desktop:text-2xl-fluid-d"
        >
          <li>Superfonik Player app is available on iPads &amp; iPhones.</li>
          <li>Profile content is always synchronised with the cloud.</li>
          <li>
            Audiobooks and podcast episodes<sup>*</sup> can be downloaded for
            offline access.
          </li>
          <li>
            Scroll through colours, volume and tracks using a whizzy wheel.
          </li>
          <li>Change the colour of the player to match your mood!</li>
          <li>
            Set sleep timers that can't be messed with by little fingers
            <sup>*</sup>.
          </li>
        </ul>
      </div>

      {/* Image block */}
      <div
        style={{
          marginTop: size(bpt, "5vw"),
        }}
        className="
          col-start-4 tablet:col-start-7 
          col-span-3 tablet:col-span-5"
      >
        <StaticImage
          src="../../images/home/player-with-finger.webp"
          alt="Superfonik Player with child's finger"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>
    </GridLayout>
  )
}

export default PlayerAppFeaturesBlock
