import BreakpointContext from "context/breakpointContext"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext, useRef } from "react"
import SwiperCore, { Autoplay, Navigation, Pagination, Virtual } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper.scss"

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Virtual, Autoplay])

/**
 * TODO
 */
const TestimonialsBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Pagination container reference
  const pagination = useRef(null)

  const TestimonialBlock = ({ text, attribution, style }) => {
    return (
      <GridLayout
        type="fluid"
        container={false}
        offsets={true}
        className="
          py-m-block-fluid
          tablet:py-t-block-fluid
          laptop:py-l-block-fluid
          desktop:py-d-block-fluid"
      >
        <p
          className="
            col-start-1 tablet:col-start-1 
            col-span-full tablet:col-span-11
            leading-snug
            text-xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
        >
          &ldquo;{text}&rdquo;
        </p>
        <p
          style={{
            marginTop: size(bpt, "3vw", "2vw", "2vw", "2vw"),
          }}
          className="
            col-start-1 col-span-full
            flex flex-row
            justify-end
            text-red
            font-bold
            text-base-fluid tablet:text-lg-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
        >
          &mdash; {attribution}
        </p>
      </GridLayout>
    )
  }

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={false}
      className="
        py-m-block-fluid
        tablet:pt-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      <Swiper
        modules={[Pagination, Navigation]}
        slidesPerView={1}
        pagination={{
          bulletElement: "div",
          el: pagination.current ? pagination.current : undefined,
          clickable: true,
        }}
        loop={true}
        autoplay={{
          delay: 6000,
        }}
        onInit={(swiper) => {
          swiper.params.pagination.el = pagination.current
          swiper.pagination.update()
        }}
        style={{
          // Override default swiper stylesheet that tries to center slides!
          marginLeft: "unset",
          marginRight: "unset",
        }}
        className="col-start-1 col-span-full"
      >
        <SwiperSlide>
          <TestimonialBlock
            text="Our car journeys to see grandparents have been transformed from in-car hell to total peace with Superfonik!"
            attribution="Nancy Reeves, Mum"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialBlock
            text="Our son was totally disinterested in reading books but he's getting through an audiobook a week now!"
            attribution="John Whiting, Dad"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialBlock
            text="Superfonik offers us a healthy diversion from too much TV when the grandkids have worn us out!"
            attribution="Luke &amp; Peter, Grandparents"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialBlock
            text="It's quick and easy to find something great to listen to when I don't have the time or energy to research books online."
            attribution="Tashana, Mum"
          />
        </SwiperSlide>
        <SwiperSlide>
          <TestimonialBlock
            text="Our daughter practically jumps into bed at night when there is an offer of a lovely audiobook to listen to!"
            attribution="Gerard, Dad"
          />
        </SwiperSlide>
      </Swiper>

      {/* Pagination */}
      <div
        className="
          col-start-1 
          col-span-full 
          flex
          justify-center"
      >
        <div ref={pagination} />
      </div>
    </GridLayout>
  )
}

export default TestimonialsBlock
