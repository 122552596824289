import BreakpointContext from "context/breakpointContext"
import { useCountries } from "context/countryContext"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"
import routes from "services/routes"

/**
 * A view of how the system works for parents and children
 */
const HowItWorksBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Store URL
  const { storeURL } = useCountries()

  /**
   * A block of copy with a heading
   */
  const CopyBlock = ({ heading, style, className, children }) => {
    return (
      <div
        style={{
          marginTop: size(bpt, "5vw"),
          marginBottom: size(bpt, "5vw"),
          ...style,
        }}
        className={`
          flex flex-col justify-center
          py-m-block-fluid-- tablet:py-0--
          ${className}
        `}
      >
        {/* Idea bulb */}
        <div
          style={{
            width: size(bpt, "4vw", "2.5vw", "2vw", "2.25vw"),
            marginBottom: size(bpt, "1vw", "0.5vw", "0.5vw", "0.5vw"),
            marginLeft: size(bpt, "-0.5vw", "-0.25vw", "-0.25vw", "-0.25vw"),
          }}
        >
          <StaticImage
            src="../../images/home/how-it-works/idea-bulb.webp"
            alt="Idea bulb"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </div>

        {/* Heading */}
        <p
          className="
            font-bold
            leading-tight
            text-red
            text-base-fluid tablet:text-lg-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
        >
          {heading}
        </p>

        {/* Copy */}
        <p
          style={{
            marginTop: size(bpt, "3vw", "1vw", "1vw", "1vw"),
          }}
          className="text-xs-fluid tablet:text-sm-fluid-t laptop:text-base-fluid-l desktop:text-2xl-fluid-d"
        >
          {children}
        </p>
      </div>
    )
  }

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      {/* Header */}
      <p
        className="
          col-start-1 col-span-full
          pt-m-block-fluid tablet:pt-0 
          tablet:pb-t-block-fluid
          tablet:text-center
          font-bold
          text-2xl-fluid tablet:text-4xl-fluid-t"
      >
        Here's how it works:
      </p>

      {/* 
        ROW 1
        --------------------------
       */}

      {/* Image block 1 */}
      <div
        style={{
          marginLeft: size(bpt, "10vw"),
          marginRight: size(bpt, "10vw"),
        }}
        className="
          col-start-1 tablet:col-start-2
          col-span-full tablet:col-span-4"
      >
        <StaticImage
          src="../../images/home/how-it-works/mum-and-son-laptop.webp"
          alt="Mum and son on laptop"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>

      {/* Copy block 1 */}
      <CopyBlock
        heading="Build your child's content library"
        style={{
          paddingRight: size(bpt, undefined, undefined, "2vw"),
        }}
        className="
          col-start-1 tablet:col-start-6
          col-span-full
          row-start-2 tablet:row-auto"
      >
        Curate each child's library of audio by helping them choose something
        exciting{" "}
        <Link to={routes.STORE(storeURL)} className="underline text-blue">
          from our store
        </Link>{" "}
        or adding titles from{" "}
        <Link to={routes.LIBRARY} className="underline text-blue">
          your existing family library
        </Link>{" "}
        of purchases, all using your browser.
      </CopyBlock>

      {/* 
        ROW 2
        --------------------------
       */}

      {/* Copy block 2 */}
      <CopyBlock
        heading="Grab the Player app"
        className="
          col-start-1 tablet:col-start-3
          col-span-full tablet:col-span-5"
      >
        Download our fab player app to any iPhone or iPad your child can use and
        we'll make sure their library of content is always up-to-date on that
        device.
      </CopyBlock>

      {/* Image block 2 */}
      <div
        style={{
          marginLeft: size(bpt, "10vw"),
          marginRight: size(bpt, "10vw"),
        }}
        className="
          col-start-1 tablet:col-start-8
          col-span-full tablet:col-span-4"
      >
        <StaticImage
          src="../../images/home/how-it-works/girl-iphone.webp"
          alt="Girl with iPhone"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>

      {/* 
        ROW 3
        --------------------------
       */}

      {/* Image block 3 */}
      <div
        style={{
          marginLeft: size(bpt, "10vw"),
          marginRight: size(bpt, "10vw"),
        }}
        className="
          col-start-1 tablet:col-start-2
          col-span-full tablet:col-span-4"
      >
        <StaticImage
          src="../../images/home/how-it-works/boy-with-ipad.webp"
          alt="Boy with iPad"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>

      {/* Copy block 3 */}
      <CopyBlock
        heading="Audio on-the-go"
        style={{
          paddingRight: size(bpt, undefined, undefined, "2vw"),
        }}
        className="
          col-start-1 tablet:col-start-6
          col-span-full
          row-start-6 tablet:row-auto"
      >
        You can choose to download audiobooks and podcasts to their device so
        audio is always available offline, making car journeys and other trips a
        pleasure!
      </CopyBlock>

      {/* 
        ROW 4
        --------------------------
       */}

      {/* Copy block 4 */}
      <CopyBlock
        heading="Independent listening"
        className="
          col-start-1 tablet:col-start-3
          col-span-full tablet:col-span-5"
      >
        Regardless of their reading ability, Superfonik enables your child to
        explore the incredible world of stories wherever and whenever they want.
      </CopyBlock>

      {/* Image block 4 */}
      <div
        style={{
          marginLeft: size(bpt, "10vw"),
          marginRight: size(bpt, "10vw"),
        }}
        className="
          col-start-1 tablet:col-start-8
          col-span-full tablet:col-span-4"
      >
        <StaticImage
          src="../../images/home/how-it-works/kids-on-floor-with-ipad.webp"
          alt="Kids on floor with iPad and toys"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>
    </GridLayout>
  )
}

export default HowItWorksBlock
