import BreakpointContext from "context/breakpointContext"
import React, { useContext } from "react"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import { StaticImage } from "gatsby-plugin-image"

/**
 * Terrific titles with cover art
 */
const AudioContentBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  /**
   * A simple wrapper that creates roudning, shadows, etc
   */
  const CoverArt = ({ className, children }) => {
    return (
      <div
        style={{
          zIndex: 1,
        }}
        // NOTE: The "relative" class and z-index enable border radius on images in Safari
        // See: https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b?permalink_comment_id=3941712#gistcomment-3941712
        className={`
          rounded-lg tablet:rounded-xl laptop:rounded-xl desktop:rounded-2xl
          shadow-md tablet:shadow-md desktop:shadow-lg
          overflow-hidden
          relative z-10--
          ${className ? className : ""}
        `}
      >
        {children}
      </div>
    )
  }

  /**
   * A row of cover art
   */
  const CoverArtRow = ({ style, children }) => {
    return (
      <div
        style={{
          marginLeft: size(bpt, "-12vw", "-10vw", "-9.7vw", "-9.7vw"),
          marginRight: size(bpt, "-12vw", "-10vw", "-9.7vw", "-9.7vw"),
          marginBottom: size(bpt, "1.6vw", "1.8vw", "1.7vw", "1.6vw"),
          ...style,
        }}
        className="
          col-start-1
          col-span-full
          grid
          grid-flow-row
          grid-cols-7 tablet:grid-cols-8
          gap-1.5 tablet:gap-3 laptop:gap-4 desktop:gap-5
          relative"
      >
        {children}
      </div>
    )
  }

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={false}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid
        overflow-hidden"
    >
      {/* Heading */}
      <p
        style={{
          marginBottom: size(bpt, "8vw", "4vw", "4vw", "4vw"),
        }}
        className="
          col-start-1 col-span-full
          text-center
          font-bold
          leading-tight
          text-xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
      >
        Try <span className="text-red">thousands of terrific titles</span>{" "}
        <br />
        for all ages and stages
      </p>

      {/*
       * Row 1: Audiobooks
       * ------------------------------------
       */}
      <CoverArtRow>
        {/* The Secret Explorers and the Lost Whales, by DK */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/secret-explorers-lost-whales.jpg"
            alt="The Secret Explorers and the Lost Whales, by DK"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* Mega Monster, by David Walliams */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/mega-monster.jpg"
            alt="Mega Monster, by David Walliams"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
            className="tablet:-m-0.75 laptop:-m-1 desktop:-m-1.75" // Remove cover border
          />
        </CoverArt>

        {/* The One and Only Ivan, by Catherine Applegate */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/one-and-only-ivan.jpg"
            alt="The One and Only Ivan, by Catherine Applegate"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* Charlie and the Chocolate Factory, by Roald Dahl */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/charlie-and-chocolate-factory.jpg"
            alt="Charlie and the Chocolate Factory, by Roald Dahl"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* The Jungle Book, by Rudyard Kipling */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/jungle-book.jpg"
            alt="The Jungle Book, by Rudyard Kipling"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* The Tiger Who Came to Tea, by Judith Kerr */}
        <CoverArt className="hidden tablet:block">
          <StaticImage
            src="../../../images/home/cover-art/tiger-who-came-to-tea.jpg"
            alt="The Tiger Who Came to Tea, by Judith Kerr"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
            className="tablet:-m-0.75 laptop:-m-1 desktop:-m-1.75" // Remove cover border
          />
        </CoverArt>

        {/* The Bacteria Book, by Steve Mould */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/bacteria-book.jpg"
            alt="The Bacteria Book, by Steve Mould"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* The Terrible Two */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/the-terrible-two.jpg"
            alt="The Terrible Two"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>
      </CoverArtRow>

      {/*
       * Row 2: Podcasts
       * ------------------------------------
       */}
      <CoverArtRow
        style={{
          left: size(bpt, "9vw", "7.5vw", "7.5vw", "7.5vw"),
        }}
      >
        {/* Fierce Girls podcast */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/fierce-girls.jpg"
            alt="Fierce Girls podcast"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* Brains On! podcast */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/brains-on-science-podcast-for-kids.jpg"
            alt="Brains On! podcast"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* Story Seeds podcast */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/the-story-seeds-podcast.jpg"
            alt="Story Seeds podcast "
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* Smash Boom Best podcast */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/smash-boom-best.jpg"
            alt="Smash Boom Best podcast"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* Forever Ago podcast */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/forever-ago.jpg"
            alt="Forever Ago podcast"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* Finn Caspian podcast */}
        <CoverArt className="hidden tablet:block">
          <StaticImage
            src="../../../images/home/cover-art/the-alien-adventures-of-finn-caspian.jpg"
            alt="Finn Caspian podcast"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>

        {/* Good Night for Rebel Girls podcast */}
        <CoverArt>
          <StaticImage
            src="../../../images/home/cover-art/good-night-stories-for-rebel-girls.jpg"
            alt="Good Night for Rebel Girls podcast"
            layout="fullWidth"
            loading="eager"
            placeholder="none"
          />
        </CoverArt>
      </CoverArtRow>
    </GridLayout>
  )
}

export default AudioContentBlock
