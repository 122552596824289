import { useLocation } from "@reach/router"
import EducationalBenefitsBlock from "components/home/benefitsBlocks/educationalBenefitsBlock"
import ImaginationBenefitsBlock from "components/home/benefitsBlocks/imaginationBenefitsBlock"
import ScreenTimeBenefitsBlock from "components/home/benefitsBlocks/screenTimeBenefitsBlock"
import AudioContentBlock from "components/home/contentBlocks/audioContentBlock"
import PodcastsContentBlock from "components/home/contentBlocks/podcastsContentBlock"
import StationsContentBlock from "components/home/contentBlocks/stationsContentBlock"
import ContentPartnersBlock from "components/home/contentPartnersBlock"
import CTABlock from "components/home/ctaBlock"
import HeroBannerBlock from "components/home/heroBannerBlock"
import HowItWorksBlock from "components/home/howItWorksBlock"
import IntroBlock from "components/home/introBlock"
import PlayerAppFeaturesBlock from "components/home/playerAppFeaturesBlock"
import PlayerColoursBlock from "components/home/playerColoursBlock"
import PlayerDetailsBlock from "components/home/playerDetailsBlock"
import StoreIntroBlock from "components/home/storeIntroBlock"
import TestimonialsBlock from "components/home/testimonialsBlock"
import TickerBlock from "components/home/tickerBlock"
import Metadata from "components/utils/metadata/metadata"
import { useFooter } from "hooks/useFooter"
import { useNavBar } from "hooks/useNavBar"
import React, { useEffect } from "react"

const Index = () => {
  // Grab the location from Reach Router for Metadata
  const location = useLocation()

  // Use the navbar
  useNavBar({
    hasNavBar: true,
    layoutType: "fluid",
    // navbarTitle: "Home",
  })

  // Use the footer
  useFooter(true)

  // Scroll back to top to prevent scroll janking
  useEffect(() => {
    return function cleanup() {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      })
    }
  }, [])

  return (
    <>
      {/* eslint-disable react/jsx-pascal-case */}
      <Metadata title="Home" useStrapLine={true} pathname={location.pathname} />

      <HeroBannerBlock />
      <TickerBlock />
      <IntroBlock />
      <StoreIntroBlock />
      <PlayerDetailsBlock />
      <AudioContentBlock />
      <PodcastsContentBlock />
      <StationsContentBlock />
      <HowItWorksBlock />
      <PlayerColoursBlock />
      <EducationalBenefitsBlock />
      <ImaginationBenefitsBlock />
      {/* <CarJourneysBenefitsBlock /> */}
      <ScreenTimeBenefitsBlock />
      <PlayerAppFeaturesBlock />
      <ContentPartnersBlock />
      <TestimonialsBlock />
      <CTABlock />
    </>
  )
}

export default Index
Index.whyDidYouRender = true
