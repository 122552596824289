import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"

/**
 * TODO
 */
const PlayerDetailsBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      {/**
       * ROW 1
       * --------------------------
       */}

      {/* Header block 1 */}
      <p
        style={{
          paddingRight: size(bpt, "15vw", "3vw", "4vw", "6vw"),
        }}
        className="
          col-start-1 tablet:col-start-1
          col-span-full tablet:col-span-6
          row-start-1 tablet:row-start-1
          font-bold
          leading-tight
          text-2xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
      >
        A super-simple, <span className="text-red">playful player</span>
      </p>

      {/* Copy block 1 */}
      <p
        style={{
          marginRight: size(bpt, undefined, "4vw", "3vw", "1vw"),
        }}
        className="
          col-start-1 tablet:col-start-1
          col-span-full tablet:col-span-6
          row-start-3 tablet:row-start-1
          flex flex-col justify-center
          text-sm-fluid tablet:text-base-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
      >
        Kids are curious about new objects &mdash; they love to play with them
        and use their little fingers. That's why we've designed an app with an
        interface that's fun and playful to use.
      </p>

      {/* Image block 1 */}
      <div
        style={{
          paddingTop: size(bpt, "8vw"),
          paddingBottom: size(bpt, "4vw"),
        }}
        className="
          col-start-3 tablet:col-start-7
          col-span-full tablet:col-span-6
          row-start-2 tablet:row-auto"
      >
        <StaticImage
          src="../../images/home/player-thumbs-vertical.webp"
          alt="Player with thumbs on main controls"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>

      {/**
       * ROW 2
       * --------------------------
       */}

      {/* Image block 2 */}
      <div
        style={{
          marginLeft: size(bpt, undefined, "2vw", "2vw", "2vw"),
          marginRight: size(bpt, "8vw", "4vw", "4vw", "4vw"),
          paddingTop: size(bpt, "8vw"),
          paddingBottom: size(bpt, "4vw"),
        }}
        className="
          col-start-1 tablet:col-start-1
          col-span-4 tablet:col-span-6
          row-start-4 tablet:row-auto"
      >
        <StaticImage
          src="../../images/home/player-volume-thumbs.webp"
          alt="Player with thumbs on secondary controls"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>

      {/* Copy block 2*/}
      <p
        className="
          col-start-1 tablet:col-start-7
          col-span-full
          flex flex-col justify-center
          text-sm-fluid tablet:text-base-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
      >
        We know that great design can help make the experience of using an
        object more pleasurable. So we've designed an app that's easy and fun to
        control &mdash; whatever your age.
      </p>

      {/**
       * ROW 3
       * --------------------------
       */}

      {/* Copy block 3 */}
      <p
        style={{
          marginRight: size(bpt, undefined, "5vw", "5vw", "5vw"),
        }}
        className="
          col-start-1
          col-span-full tablet:col-span-6
          flex flex-col justify-center          
          text-sm-fluid tablet:text-base-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
      >
        Our app works beautifully on an iPad too, giving little fingers even
        bigger buttons to play with and allowing them to explore their content
        library with more detail.
      </p>

      {/* Image block 3 */}
      <div
        style={{
          paddingTop: size(bpt, "8vw", "5vw"),
          paddingBottom: size(bpt, "8vw"),
        }}
        className="
          col-start-3 tablet:col-start-7
          col-span-4 tablet:col-span-6
          row-start-6 tablet:row-auto"
      >
        <StaticImage
          src="../../images/home/ipad-book-cover.webp"
          alt="iPad with book cover"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>
    </GridLayout>
  )
}

export default PlayerDetailsBlock
