import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"

/**
 * A block about the educational benefits of audiobooks.
 */
const EducationalBenefitsBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      {/* Image block */}
      <div
        style={{
          marginLeft: size(bpt, "10vw"),
          marginRight: size(bpt, "10vw"),
        }}
        className="
          col-start-1 tablet:col-start-1
          col-span-full tablet:col-span-9
          tablet:row-start-1
          flex flex-col
          justify-end"
      >
        <StaticImage
          src="../../../images/home/educational-tools.webp"
          alt="Player with educational tools"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>

      {/* Copy block */}
      <div
        style={{
          marginTop: size(bpt, "4vw"),
          marginRight: size(bpt, undefined, "1vw"),
          paddingBottom: size(bpt, undefined, "13vw", "13vw", "12.5vw"),
        }}
        className="
          col-start-1 tablet:col-start-7
          col-span-full
          tablet:row-start-1
          flex flex-col justify-center"
      >
        <p
          style={{
            paddingRight: size(bpt, "12vw", "5vw", "5vw", "5vw"),
          }}
          className="
            font-bold
            leading-tight
            text-2xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
        >
          Audiobooks are <span className="text-red">highly educational</span>
        </p>

        <p
          style={{
            marginTop: size(bpt, "3vw", "2vw", "2vw", "2vw"),
          }}
          className="text-sm-fluid tablet:text-base-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
        >
          Audiobooks can help{" "}
          <a
            className="underline text-blue"
            href="https://literacytrust.org.uk/news/we-release-research-review-benefits-audiobooks-literacy/"
          >
            unlock your child’s love of reading
          </a>
          . They also{" "}
          <a
            className="underline text-blue"
            href="https://cdn.literacytrust.org.uk/media/documents/Audiobooks_and_literacy_2020.pdf"
          >
            build comprehension skills and vocabulary
          </a>{" "}
          and encourage less confident readers to access a wider range of
          stories.
        </p>
      </div>
    </GridLayout>
  )
}

export default EducationalBenefitsBlock
