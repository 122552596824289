import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"

/**
 * TODO
 */
const StoreIntroBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      {/* Bookshelf image */}
      <div
        style={{
          marginLeft: size(bpt, "4vw"),
          marginRight: size(bpt, "4vw"),
          marginBottom: size(bpt, "4vw", "3vw", "3vw", "3vw"),
        }}
        className="
          col-start-1 tablet:col-start-3
          col-span-full tablet:col-span-8"
      >
        <StaticImage
          src="../../images/home/player-bookshelf.webp"
          alt="Bookshelf with Player"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>

      {/* Header */}
      <p
        style={{
          paddingRight: size(bpt, "10vw", undefined, "2vw"),
        }}
        className="
          col-start-1 tablet:col-start-2
          col-span-full tablet:col-span-10
          font-bold
          leading-tight
          text-xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
      >
        We've curated the best children's audio content,{" "}
        <span className="text-red">all in one place.</span>
      </p>

      {/* Copy */}
      <p
        style={{
          marginTop: size(bpt, "3vw", "2vw", "2vw", "2vw"),
          paddingRight: size(bpt, undefined, "1vw", "1vw"),
        }}
        className="
          col-start-1 tablet:col-start-2
          col-span-full tablet:col-span-8
          leading-snug
          text-sm-fluid tablet:text-base-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
      >
        From classics to family favorites. From the greatest-ever authors to the
        greatest-newest authors. From podcasts to radio. From early learning to
        nerdy wordies, <b>Superfonik has something for everyone!</b>
      </p>
    </GridLayout>
  )
}

export default StoreIntroBlock
