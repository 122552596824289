import BreakpointContext from "context/breakpointContext"
import React, { useContext } from "react"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import { StaticImage } from "gatsby-plugin-image"

/**
 * TODO
 */
const StationsContentBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-0 tablet:pb-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      {/* Copy block */}
      <div
        style={{
          marginTop: size(bpt, "8vw"),
          marginLeft: size(bpt, undefined, "-2.5vw", "-3vw", "-3vw"),
        }}
        className="
          col-start-1 tablet:col-start-7
          col-span-full tablet:col-span-full
          row-start-1
          flex flex-col justify-end"
      >
        <p
          style={{
            paddingLeft: size(bpt, "41vw"),
            paddingBottom: size(bpt, "1vw"),
          }}
          className="
            font-bold
            leading-tight
            text-2xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
        >
          Turn on, <br /> <span className="text-red">tune in, </span>
          <br />
          hop about!
        </p>

        <p
          style={{
            marginTop: size(bpt, "3vw", "2vw", "2vw", "2vw"),
          }}
          className="text-sm-fluid tablet:text-base-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
        >
          We've got never-ending streams of the best kids' party tunes, rock,
          pop &amp; classical music available 24 hours a day, from radio
          stations all over the globe.
        </p>
      </div>

      {/* Image block */}
      <div
        style={{
          marginRight: size(bpt, "-1vw", "-1vw"),
          paddingBottom: size(bpt, "25vw"),
          paddingRight: size(bpt, "5vw"),
        }}
        className="
          col-start-1 tablet:col-start-1
          col-span-3 tablet:col-span-5
          row-start-1"
      >
        <StaticImage
          src="../../../images/home/ipad-radio-tower.webp"
          alt="Player broadcasting tower"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>
    </GridLayout>
  )
}

export default StationsContentBlock
