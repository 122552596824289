import Button from "components/controls/button"
import BreakpointContext from "context/breakpointContext"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"
import routes from "services/routes"

/**
 * The Sign up call-to-action block
 */
const CTABlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <div
      className="
        grid grid-cols-1
        mt-m-block-fluid
        tablet:mt-t-block-fluid
        laptop:mt-l-block-fluid
        desktop:mt-d-block-fluid"
    >
      <StaticImage
        src="../../images/home/kid-with-megaphone.webp"
        alt="Kid with megaphone"
        layout="fullWidth"
        loading="eager"
        placeholder="none"
        className="row-start-1 col-start-1"
      />
      <GridLayout
        type="fluid"
        container={false}
        offsets={true}
        className="row-start-1 col-start-1"
      >
        <div
          style={{
            marginTop: "12vw",
          }}
          className="
            col-start-1 
            col-span-3 tablet:col-span-6"
        >
          <p
            style={{
              fontSize: "5vw",
            }}
            className="
              text-white
              font-medium
              leading-tight"
          >
            Time to get listening!
          </p>

          <Button
            type="primary"
            text="Sign up"
            colorBackground={true}
            size={size(bpt, "xs", "lg", "xl", "xl")}
            shadowHeight={size(bpt, 2, 3, 4, 5)}
            onClick={() => {
              navigate(routes.SIGN_UP)
            }}
            style={{
              width: size(bpt, "24vw", "23.5vw", "24vw", "24vw"),
              marginTop: size(bpt, "3vw", "4vw", "4vw", "4vw"),
            }}
          />
        </div>
      </GridLayout>
    </div>
  )
}

export default CTABlock
