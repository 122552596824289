import BreakpointContext from "context/breakpointContext"
import { size } from "helpers/responsiveSize"
import { useMountOnLoad } from "hooks/useMountOnLoad"
import React, { useContext } from "react"

/**
 * TODO
 */
const TickerBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  // Deterine mount state
  const isMounted = useMountOnLoad()

  return (
    <p
      style={{
        padding: size(bpt, "1.5vw", "1vw", "1vw", "1vw"),
        visibility: isMounted ? "visible" : "hidden",
      }}
      className="
        text-center
        text-white
        bg-red
        text-xxs-fluid 
        tablet:text-sm-fluid-t 
        laptop:text-base-fluid-l 
        desktop:text-lg-fluid-d"
    >
      Now available in the United Kingdom!
    </p>
  )
}

export default TickerBlock
