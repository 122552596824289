import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"

/**
 * TODO
 */
const ImaginationBenefitsBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      {/* Copy block */}
      <div
        style={{
          paddingRight: size(bpt, undefined, "5vw"),
          marginTop: size(bpt, "4vw"),
        }}
        className="
          col-start-1 tablet:col-start-1
          col-span-full tablet:col-span-6
          row-start-2 tablet:row-start-1
          flex flex-col justify-center"
      >
        <p
          style={{
            paddingRight: size(bpt, "6vw", "5vw", "10vw", "13vw"),
          }}
          className="
            font-bold
            leading-tight
            text-2xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
        >
          Help their <span className="text-red">imaginations take flight</span>
        </p>

        <p
          style={{
            marginTop: size(bpt, "3vw", "2vw", "2vw", "2vw"),
          }}
          className="text-sm-fluid tablet:text-base-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
        >
          The best stories open up whole new worlds for your child by sparking
          their imagination and stimulating their curiosity.
        </p>
      </div>

      {/* Image block */}
      <div
        style={{
          marginLeft: size(bpt, "10vw"),
          marginRight: size(bpt, "10vw"),
        }}
        className="
          col-start-1 tablet:col-start-7 
          col-span-full tablet:col-span-6
          row-start-1 tablet:row-start-1"
      >
        <StaticImage
          src="../../../images/home/unicorn-player.webp"
          alt="Unicorn Player"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>
    </GridLayout>
  )
}

export default ImaginationBenefitsBlock
