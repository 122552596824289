import BreakpointContext from "context/breakpointContext"
import { StaticImage } from "gatsby-plugin-image"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import React, { useContext } from "react"

/**
 * The range of player UI colours
 */
const PlayerColoursBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      <p
        className="
          col-start-1 col-span-full
          text-center
          font-bold
          text-2xl-fluid tablet:text-4xl-fluid-t laptop:text-5xl-fluid-l"
      >
        Super <span className="text-red">bright</span>
      </p>

      <p
        style={{
          marginTop: size(bpt, undefined, "1vw", "1vw", "1vw"),
          marginBottom: size(bpt, undefined, "4vw", "4vw", "4vw"),
        }}
        className="
          col-start-1 col-span-full
          row-start-3 tablet:row-start-2
          text-center
          font-medium tablet:font-normal
          text-base-fluid tablet:text-lg-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
      >
        Choose from{" "}
        <span className="text-red tablet:text-grey-darker">
          loads of lovely colours
        </span>{" "}
        to suit your mood!
      </p>

      <div
        style={{
          marginLeft: "-1vw",
          marginRight: "-1.5vw",
          marginTop: size(bpt, "3vw"),
          marginBottom: size(bpt, "3vw"),
        }}
        className="col-start-1 col-span-full"
      >
        <StaticImage
          src="../../images/home/player-colours.webp"
          alt="Player colour variants"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>
    </GridLayout>
  )
}

export default PlayerColoursBlock
