import BreakpointContext from "context/breakpointContext"
import React, { useContext } from "react"
import { size } from "helpers/responsiveSize"
import GridLayout from "layouts/gridLayout"
import { StaticImage } from "gatsby-plugin-image"

/**
 * POdcast content
 */
const PodcastsContentBlock = () => {
  // The current breakpoint
  const bpt = useContext(BreakpointContext)

  return (
    <GridLayout
      type="fluid"
      container={false}
      offsets={true}
      className="
        py-m-block-fluid
        tablet:py-t-block-fluid
        laptop:py-l-block-fluid
        desktop:py-d-block-fluid"
    >
      {/* Copy block */}
      <div
        style={{
          marginTop: size(bpt, "8vw"),
        }}
        className="
          col-start-1 tablet:col-start-1
          col-span-full tablet:col-span-6
          row-start-1
          flex flex-col justify-end tablet:justify-center"
      >
        <p
          style={{
            paddingRight: size(bpt, "46vw"),
          }}
          className="
            font-bold
            leading-tight
            text-2xl-fluid tablet:text-3xl-fluid-t laptop:text-4xl-fluid-l desktop:text-5xl-fluid-d"
        >
          Plenty of <span className="text-red">absorbing podcasts</span>
        </p>

        <p
          style={{
            marginTop: size(bpt, "3vw", "2vw", "2vw", "2vw"),
            paddingBottom: size(bpt, undefined, "5vw", "5vw"),
          }}
          className="text-sm-fluid tablet:text-base-fluid-t laptop:text-xl-fluid-l desktop:text-3xl-fluid-d"
        >
          We've packed all the best children's podcasts into our store so you
          can subscribe for{" "}
          <span className="font-bold">endless free episodes</span> on a wide
          range of interesting subjects.
        </p>
      </div>

      {/* Image block */}
      <div
        style={{
          marginLeft: size(bpt, "-3vw", "-3vw", "-3.5vw", "-4vw"),
          paddingBottom: size(bpt, "23.5vw"),
        }}
        className="
          col-start-4 tablet:col-start-8
          col-span-full tablet:col-span-5
          row-start-1"
      >
        <StaticImage
          src="../../../images/home/podcasts-microphone.webp"
          alt="Player with microphone icon"
          layout="fullWidth"
          loading="eager"
          placeholder="none"
        />
      </div>
    </GridLayout>
  )
}

export default PodcastsContentBlock
