import React from "react"

/**
 * A simple block of text, with an optional heading
 */
const TextBlock = ({ heading, style, className, children }) => {
  return (
    <div style={style} className={className}>
      {/* Heading */}
      {heading && (
        <p
          style={{
            marginBottom: "1vw",
          }}
          className="
            text-red 
            font-bold 
            text-base-fluid tablet:text-xl-fluid-t laptop:text-2xl-fluid-l desktop:text-4xl-fluid-d"
        >
          {heading}
        </p>
      )}
      {/* Text */}
      <p
        className="
          text-xs-fluid tablet:text-sm-fluid-t laptop:text-lg-fluid-l desktop:text-2xl-fluid-d"
      >
        {children}
      </p>
    </div>
  )
}

export default TextBlock
